import {useContext, useEffect, useState} from "react";
import {Link, navigate} from "gatsby";
import * as React from "react";
import GlobalContext from "../../context/GlobalContext";
import {Disclosure} from "@headlessui/react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Footer = () => {
    const gContext = useContext(GlobalContext);
    const [open] = useState(false)
    const [footerNavigation, setFooterNavigation] = useState({});
    useEffect(() => {
        setFooterNavigation({
            prodotti: [
                {name: 'Tende da sole', href: 'tendedasole'},
                {name: 'Pergole', href: 'pergole'},
                {name: 'Pergole bioclimatiche', href: 'pergolebioclimatiche'},
                {name: 'Rivestimenti', href: 'rivestimenti'},
                {name: 'Arredamento di interni', href: 'arredamentointerni'},
            ],
            azienda: [
                {name: 'Chi siamo', href: 'about'},
                {name: 'News', href: 'news'},
                {name: 'Contatti', href: 'contatti'},
            ],
            legale: [
                {name: 'Informativa privacy', href: 'privacy'},
                {name: 'Informativa cookie', href: 'cookie'},

            ],
            social: [
                {
                    name: 'Facebook',
                    href: 'https://www.facebook.com/tappezzeriaorsillipisa/',
                    icon: (props) => (
                        <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none"
                             strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"/>
                        </svg>
                    ),
                },
                {
                    name: 'Instagram',
                    href: 'https://www.instagram.com/tappezzeria_orsilli/?hl=it',
                    icon: (props) => (
                        <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none"
                             strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <rect x="4" y="4" width="16" height="16" rx="4"/>
                            <circle cx="12" cy="12" r="3"/>
                            <line x1="16.5" y1="7.5" x2="16.5" y2="7.501"/>
                        </svg>
                    ),
                },
            ],
        })
    }, [gContext])
    return (
        <footer className="bg-black text-gray-500 relative data-dark-header data-dark-last" aria-labelledby="footer-heading">
            <div className="max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8 py-10 sm:py-20 xl:py-40">
                <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div className="space-y-4 xl:col-span-1 text-base flex flex-col items-center xl:items-start text-center sm:text-left">
                        <img className="w-auto h-12 lg:h-16" src={'/logo-white.svg'} alt="Orsilli"  width="197" height="72"/>
                        <div className="text-center xl:text-left">
                            <p>TAPPEZZERIA ORSILLI Srl</p>
                            <p>Via Volturno, 17, 56126 Pisa (PI)</p>
                            <p>Tel. <a href="tel:05040923" className="hover:underline">050.40923</a></p>
                            <p>Mail: <a href="mailto:info@tappezzeriaorsillisrl.com" className="hover:underline">info@tappezzeriaorsillisrl.com</a></p>
                        </div>
                    </div>
                    <div className="hidden mt-12 sm:grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-sm text-white tracking-wider uppercase">{gContext.t('Prodotti')}</h3>
                                <ul className="mt-2">
                                    {(footerNavigation?.prodotti || []).map((item) => (
                                        <li key={item.name}>
                                            <Link to={gContext.url(item.href)} className="text-base hover:text-white duration-200 transition ease-in-out">
                                                {gContext.t(item.name)}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-12 md:mt-0">
                                <h3 className="text-sm text-white tracking-wider uppercase">{gContext.t('Azienda')}</h3>
                                <ul className="mt-2">
                                    {(footerNavigation?.azienda || []).map((item) => (
                                        <li key={item.name}>
                                            <Link to={gContext.url(item.href)} className="text-base hover:text-white duration-200 transition ease-in-out">
                                                {gContext.t(item.name)}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-sm text-white tracking-wider uppercase">{gContext.t('Legale')}</h3>
                                <ul className="mt-2">
                                    {(footerNavigation?.legale || []).map((item) => (
                                        <li key={item.name}>
                                            <Link to={gContext.url(item.href)} className="text-base hover:text-white duration-200 transition ease-in-out">
                                                {gContext.t(item.name)}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-12 md:mt-0">
                                <h3 className="text-sm text-white tracking-wider uppercase">{gContext.t('Social')}</h3>
                                <ul className="mt-2 space-y-2">
                                    {(footerNavigation?.social || []).map((item) => (
                                        <li key={item.name}>
                                            <a href={item.href} target="_blank" rel="noreferrer" className="hover:text-white transition duration-200 ease-in">
                                                <span className="sr-only">{item.name}</span>
                                                <item.icon className="h-7 w-7" aria-hidden="true"/>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="block sm:hidden mt-12 divide-y divide-white/10 border-y border-white/10">
                        <Disclosure as="div" className="space-y-1">
                            <Disclosure.Button id="div-4" className="group w-full text-base flex items-center justify-between px-2 py-3">
                                {gContext.t('Prodotti')}
                                <svg className={classNames(
                                    open ? 'rotate-180' : '',
                                    'ml-2 flex-shrink-0 h-5 w-5 transform transition ease-in-out duration-150'
                                )} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="ml-4 pb-2">
                                {(footerNavigation?.prodotti || []).map((item) => (
                                    <Link key={item.name} to={gContext.url(item.href)}
                                          onClick={e => {
                                              e.preventDefault();
                                              setTimeout(() => {
                                                  navigate(gContext.url(item.href));
                                              }, 700);
                                          }}
                                          className={`text-base flex items-center rounded-md py-1 transition ease-in-out duration-200 opacity-50 ${gContext.isCurrentRoute(item.href) ? 'opacity-100' : 'hover:opacity-100'}`}>
                                        {gContext.t(item.name)}
                                    </Link>
                                ))}
                            </Disclosure.Panel>
                        </Disclosure>
                        <Disclosure as="div" className="space-y-1">
                            <Disclosure.Button id="div-3" className="group w-full text-base flex items-center justify-between px-2 py-3">
                                {gContext.t('Azienda')}
                                <svg className={classNames(
                                    open ? 'rotate-180' : '',
                                    'ml-2 flex-shrink-0 h-5 w-5 transform transition ease-in-out duration-150'
                                )} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="ml-4 pb-2">
                                {(footerNavigation?.azienda || []).map((item) => (
                                    <Link key={item.name} to={gContext.url(item.href)}
                                          onClick={e => {
                                              e.preventDefault();
                                              setTimeout(() => {
                                                  navigate(gContext.url(item.href));
                                              }, 700);
                                          }}
                                          className={`text-base flex items-center rounded-md py-1 transition ease-in-out duration-200 opacity-50 ${gContext.isCurrentRoute(item.href) ? 'opacity-100' : 'hover:opacity-100'}`}>
                                        {gContext.t(item.name)}
                                    </Link>
                                ))}
                            </Disclosure.Panel>
                        </Disclosure>
                        <Disclosure as="div" className="space-y-1">
                            <Disclosure.Button id="div-2" className="group w-full text-base flex items-center justify-between px-2 py-3">
                                {gContext.t('Legale')}
                                <svg className={classNames(
                                    open ? 'rotate-180' : '',
                                    'ml-2 flex-shrink-0 h-5 w-5 transform transition ease-in-out duration-150'
                                )} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="ml-4 pb-2">
                                {(footerNavigation?.legale || []).map((item) => (
                                    <Link key={item.name} to={gContext.url(item.href)}
                                          onClick={e => {
                                              e.preventDefault();
                                              setTimeout(() => {
                                                  navigate(gContext.url(item.href));
                                              }, 700);
                                          }}
                                          className={`text-base flex items-center rounded-md py-1 transition ease-in-out duration-200 opacity-50 ${gContext.isCurrentRoute(item.href) ? 'opacity-100' : 'hover:opacity-100'}`}>
                                        {gContext.t(item.name)}
                                    </Link>
                                ))}
                            </Disclosure.Panel>
                        </Disclosure>
                        <Disclosure as="div" className="space-y-1">
                            <Disclosure.Button id="div-1" className="group w-full text-base flex items-center justify-between px-2 py-3">
                                {gContext.t('Social')}
                                <svg className={classNames(
                                    open ? 'rotate-180' : '',
                                    'ml-2 flex-shrink-0 h-5 w-5 transform transition ease-in-out duration-150'
                                )} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="ml-4 pb-2 space-y-3">
                                {(footerNavigation?.social || []).map((item) => (
                                    <div key={item.name}>
                                        <a href={item.href} target="_blank" rel="noreferrer" className="hover:text-white transition duration-200 ease-in">
                                            <span className="sr-only">{item.name}</span>
                                            <item.icon className="h-7 w-7" aria-hidden="true"/>
                                        </a>
                                    </div>
                                ))}
                            </Disclosure.Panel>
                        </Disclosure>
                    </div>
                </div>
            </div>

            <div className="border-t border-white/10 px-4 sm:px-6 lg:px-8 py-3.5 w-full text-sm font-light flex flex-col sm:flex-row items-center justify-between text-center sm:text-left">
                <p>{gContext.t('© 2022 Tappezzeria Orsilli Srl - Tutti i diritti riservati')}</p>
                <p>website by <a href="https://idsign.it/" rel="noreferrer" target="_blank" className="hover:underline">idsign</a></p>
            </div>
        </footer>
    )
}
export default Footer
